import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import markerShadowPng from 'leaflet/dist/images/marker-shadow.png';
import AvatarMale from '../avatarm.png';
import AvatarFemale from '../avatarf.png';

// Create a custom icon
const customIcon = new L.Icon({
  iconUrl: markerIconPng,
  shadowUrl: markerShadowPng,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

// Component to handle map center updates
function LocationMarker() {
  const [position, setPosition] = useState(null);
  const map = useMap();

  useEffect(() => {
    map.locate().on("locationfound", function (e) {
      setPosition(e.latlng);
      map.flyTo(e.latlng, 10); // Zoom level 6 is good for country-level view
    });
  }, [map]);

  return position === null ? null : (
    <Marker position={position} icon={customIcon}>
      <Popup>You are here</Popup>
    </Marker>
  );
}

const MapComponent = () => {
    const markers = [
        
        { 
        coords: [52.355, 4.777], 
        text: "Amsterdam - 1 life saved here.", 
        link: "https://x.com/StarLinkSavedML", 
        image: AvatarFemale
        },
        { 
        coords: [51.5074, -0.1278], 
        text: "London - Incredible rescue powered by Starlink!", 
        link: "https://x.com/StarLinkSavedML",
        image: AvatarMale
        },
        { 
        coords: [48.8566, 2.3522], 
        text: "Paris - Starlink intervention makes the difference!", 
        link: "https://x.com/StarLinkSavedML",
        image: AvatarFemale
        },
        { 
        coords: [52.5200, 13.4050], 
        text: "Berlin - Starlink plays a key role in the rescue!", 
        link: "https://x.com/StarLinkSavedML",
        image: AvatarMale
        }
    ];
       
    return (
        <div style={{ position: 'relative' }}>
            <MapContainer 
                center={[0, 0]} 
                zoom={2} 
                scrollWheelZoom={true} 
                style={{ height: '400px', zIndex: 1 }}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker />
                {markers.map((marker, index) => (
                    <Marker key={index} position={marker.coords} icon={customIcon}>
                        <Popup>
                            <div>
                                {marker.image && <img src={marker.image} alt={marker.text} style={{ maxWidth: '200px', maxHeight: '150px' }} />}
                                {marker.text}
                                <br />
                                <a href={marker.link} target="_blank" rel="noopener noreferrer">Read the story </a>
                                <br />Lat-Lon:
                                {marker.coords}
                            </div>
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
        </div>
    );
};
  
export default MapComponent;
