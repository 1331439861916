import React from 'react';

const Checkbox = ({ checked, onCheckedChange, id, className, ...props }) => {
  return (
    <input
      type="checkbox"
      id={id}
      checked={checked}
      onChange={(e) => onCheckedChange(e.target.checked)}
      className={`h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-2 focus:ring-indigo-500 ${className}`}
      {...props}
    />
  );
};

export default Checkbox;
