// This is version 2 of the Map - editing this one to get MapComponent.js working

import React, { useState, useEffect } from 'react';
import { Map, Upload, MessageSquare } from 'lucide-react';
import MapComponent from './components/other/MapComponent.js';
import SubmitStory from './components/other/SubmitStory.jsx';
// Import the new component
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "./components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./components/ui/dialog";
import { Button } from "./components/ui/button";
// import { Input } from "./components/ui/input"; // Removed unused import
// import { Textarea } from "./components/ui/textarea"; // Removed unused import
// import {
//   Select,
//   SelectContent,
//   SelectItem,
//   SelectTrigger,
//   SelectValue,
// } from "./components/ui/select"; // Removed unused imports

import { auth } from "./lib/firebase"; // Import Firebase auth
import {
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  // createUserWithEmailAndPassword, // Removed unused import
  // signInWithEmailAndPassword, // Removed unused import
} from "firebase/auth";

const LandingPage = () => {
  const [selectedContentType, setSelectedContentType] = useState('text');
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [user, setUser] = useState(null); // Add user state
  // const [email, setEmail] = useState(""); // Removed unused state
  // const [password, setPassword] = useState(""); // Removed unused state
  // const [loginMethod, setLoginMethod] = useState("google"); // Removed unused state


  const handleSubmit = () => {
    // Handle form submission here (e.g., send data to Firebase)
    console.log("Title:", title, "Content Type:", selectedContentType, "Content:", content);
    // Reset form after submission
    setTitle("");
    setSelectedContentType("text");
    setContent("");
  };

  // Sign in with Google
  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  // Check for user authentication changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
    // Unsubscribe when component unmounts
  }, []); // Empty dependency array ensures this runs once

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Navigation Bar */}
      <nav className="bg-white shadow-sm p-4">
        <div className="container mx-auto flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Map className="h-6 w-6" />
            <h1 className="text-xl font-bold">Starlink Saved My Life v0.6</h1> {/* Corrected capitalization */}
          </div>
          <div className="flex space-x-4">
            {user ? ( // Conditionally render login/logout
              <div>
                <span>Welcome, {user.displayName}!</span>
                <Button onClick={() => auth.signOut()}>Logout</Button>
              </div>
            ) : (
              <Button onClick={signInWithGoogle}>Login with Google</Button>
            )}
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="outline" className="flex items-center space-x-2">
                  <Upload className="h-4 w-4" />
                  <span>Share your Story</span>
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Share Your Story</DialogTitle>
                </DialogHeader>
                <SubmitStory
                  onSubmit={handleSubmit}
                  title={title}
                  setTitle={setTitle}
                  selectedContentType={selectedContentType}
                  setSelectedContentType={setSelectedContentType}
                  content={content}
                  setContent={setContent}
                />
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Map Section */}
        <div className="md:col-span-2">
          <Card>
            <CardHeader>
              <CardTitle>Starlink Survival Map a.k.a. The Gratidude Grid</CardTitle> {/* Corrected capitalization */}
            </CardHeader>
            <CardContent>
              <div className="bg-gray-200 h-96 rounded-lg">
                <MapComponent />
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Stories Section */}
        <div className="md:col-span-1">
          <Card>
            <CardHeader>
              <CardTitle>Recent Stories</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {[1, 2, 3].map((index) => (
                  <Card key={index}>
                    <CardContent className="p-4">
                      <div className="flex items-start justify-between">
                        <div>
                          <h3 className="font-semibold">Story Title {index}</h3>
                          <p className="text-sm text-gray-500">Uploaded from Location</p>
                          <p className="mt-2">How Starlink made life better...</p>
                        </div>
                        <Button variant="ghost" size="icon">
                          <MessageSquare className="h-4 w-4" />
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
