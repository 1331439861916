import React, { useState } from 'react';

const Popover = ({ children, className, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`relative inline-block ${className}`} {...props}>
      <div onClick={() => setIsOpen(!isOpen)}>{children[0]}</div>
      {isOpen && (
        <div className="absolute mt-2 py-2 px-4 bg-white shadow-md rounded-md">
          {children[1]}
        </div>
      )}
    </div>
  );
};

Popover.Content = ({children}) => <>{children}</>;
Popover.Trigger = ({children}) => <>{children}</>;

export default Popover;
