import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAnBcOKOraT95gu9c5c3QYFaqyK0r_4uKU",
  authDomain: "starlinksavedmylife.firebaseapp.com", 
  projectId: "starlinksavedmylife",
  storageBucket: "starlinksavedmylife.firebasestorage.app",
  messagingSenderId: "991276689122",
  appId: "1:991276689122:web:2625abc74750df8dbc20bb",
  measurementId: "G-P6N8K6F65X"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);