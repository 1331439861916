import React, { useState } from 'react';
import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { Button } from "../ui/button";
import Label from "../ui/label";
import Checkbox from "../ui/checkbox";
import Calendar from "../ui/calendar";
import Popover from "../ui/popover";
import PopoverContent from "../ui/popover";
import PopoverTrigger from "../ui/popover";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from "lucide-react";

const SubmitStory = () => {
  const [formData, setFormData] = useState({
    storyTitle: '',
    storyDescription: '',
    storyType: '',
    category: '',
    location: '',
    date: null,
    tags: [],
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    consent: false
  });

  const Calendar = ({ selected, onSelect, ...props }) => {
    return (
        <DatePicker
            selected={selected}
            onChange={onSelect}
            {...props}
        />
    );
};


// State to control the visibility of the contact information fields
  const [showContactInfo, setShowContactInfo] = useState(false);


  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Add your submission logic here
  };

  const categories = [
    "Medical Emergencies",
    "Disaster Recovery",
    "Rescue Me Please",
    "Emergency Situation",
    "Educational Impact",
    "Other"
  ];

  const locations = [
    "Global",
    "North America",
    "South America",
    "Europe",
    "Middle East",
    "Africa",
    "Asia",
    "Oceania",
  ];

  const tags = [
    "Emergency",
    "Hurricane",
    "Flood",
    "Tornado"
  ];

  return (
    <form className="space-y-6 p-4">
      <div className="space-y-2">
        <Label htmlFor="title">Story Title</Label>
        <Input
          id="title"
          name="storyTitle"
          placeholder="Enter your story title"
          value={formData.storyTitle}
          onChange={(e) => handleInputChange('storyTitle', e.target.value)}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="description">Story Description</Label>
        <Textarea
          id="description"
          name="storyDescription"
          placeholder="Share your story..."
          value={formData.storyDescription}
          onChange={(e) => handleInputChange('storyDescription', e.target.value)}
          className="h-32"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="type">Story Type</Label>
        <Select
          value={formData.storyType}
          onValueChange={(value) => handleInputChange('storyType', value)}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select story type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="text">Text</SelectItem>
            <SelectItem value="audio">Audio</SelectItem>
            <SelectItem value="video">Video</SelectItem>
            <SelectItem value="youtube">YouTube Link</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="space-y-2">
        <Label htmlFor="category">Category</Label>
        <Select
          value={formData.category}
          onValueChange={(value) => handleInputChange('category', value)}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select category" />
          </SelectTrigger>
          <SelectContent>
            {categories.map((category) => (
              <SelectItem key={category} value={category.toLowerCase().replace(/ /g, '-')}>
                {category}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="space-y-2">
        <Label htmlFor="location">Location</Label>
        <Select
          value={formData.location}
          onValueChange={(value) => handleInputChange('location', value)}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select location" />
          </SelectTrigger>
          <SelectContent>
            {locations.map((location) => (
              <SelectItem key={location} value={location.toLowerCase()}>
                {location}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="space-y-2">
        <Label>Tags</Label>
        <div className="flex flex-wrap gap-4">
          {tags.map((tag) => (
            <div key={tag} className="flex items-center space-x-2">
              <Checkbox
                id={`tag-${tag}`}
                checked={formData.tags?.includes(tag)}
                onCheckedChange={(checked) => {
                  const updatedTags = checked 
                    ? [...(formData.tags || []), tag]
                    : formData.tags?.filter(t => t !== tag) || [];
                  handleInputChange('tags', updatedTags);
                }}
              />
              <Label htmlFor={`tag-${tag}`}>{tag}</Label>
            </div>
          ))}
        </div>
      </div>

      <div className="space-y-4">
        <Label>Contact Information (Optional)</Label>
        <div className="space-y-2">
          <Input
            placeholder="Name"
            value={formData.contactName}
            onChange={(e) => handleInputChange('contactName', e.target.value)}
          />
          <Input
            type="email"
            placeholder="Email"
            value={formData.contactEmail}
            onChange={(e) => handleInputChange('contactEmail', e.target.value)}
          />
          <Input
            type="tel"
            placeholder="Phone (optional)"
            value={formData.contactPhone}
            onChange={(e) => handleInputChange('contactPhone', e.target.value)}
          />
        </div>
      </div>

      <div className="flex items-center space-x-2">
        <Checkbox
          id="consent"
          checked={formData.consent}
          onCheckedChange={(checked) => handleInputChange('consent', checked)}
        />
        <Label htmlFor="consent">
          Yes! Share my story publicly on StarlinkSavedMyLife.com
        </Label>
      </div>

      <div className="flex justify-end">
        <Button 
          type="submit" 
          onClick={handleSubmit}
          disabled={!formData.consent}
        >
          Publish Story
        </Button>
      </div>
    </form>
  );
};

export default SubmitStory;