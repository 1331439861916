import './App.css';
import LandingPage3 from './LandingPage3'; // Adjust the path if necessary
import "react-datepicker/dist/react-datepicker.css"; 

function App() {
  return (
    <div className="App">
      <LandingPage3 /> 
    </div>
  );
}

export default App;
